
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100 900; /* Supports weights from Thin (100) to Black (900) */
    font-display: swap;
    src: url('../Assets/fonts/Inter-VariableFont.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 100 900; /* Supports weights from Thin (100) to Black (900) */
    font-display: swap;
    src: url('../Assets/fonts/RobotoMono-VariableFont.ttf') format('truetype');
  }