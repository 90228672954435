@import '../abstracts/variable';
@import '../abstracts/mixins';
@import '../abstracts/media';

.header {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: $bg-transparent-light;
  color: $color-white;
  @include transition(all, $transition-medium);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: $z-index-header;
  position: sticky;
  top: 0;

  &.sticky {
    background-color: $bg-transparent-dark;
  }

  nav {
    @include flex-center(row);
    align-items: center;

    .header-logo-container {
      width: 50px;
      height: 50px;

      @include flex-center();
      @include logo-hover-animation; // Apply hover animation mixin here

      .header-logo {
        width: 100%;
        height: auto;
      }
    }

    .nav-links {
      list-style: none;
      display: flex;
      gap: 1rem;
      margin-left: auto;
      a {
        color: $color-white;
        text-decoration: none;
        font-size: 0.9rem;
        padding: 0.5rem;

        &:hover {
          color: $color-black;
        }
      }
    }
  }
}

main {
  padding-top: 80px;
}

// Add responsiveness
@include respond-to(mobile) {
  .header {
    nav {
      flex-direction: column;

      .nav-links {
        margin-left: 0;
        gap: 1rem;
        padding-top: 0.5rem;
        a {
          padding:0;
          font-size: 1rem;
        }
      }
    }
  }
}