@import '../abstracts/variable';
@import '../abstracts/mixins';
@import '../abstracts/media';

/* Error Page Styles */
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    color: $color-gray-dark;
  }
  
  .error-page h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .error-message {
    background-color: $color-gray-light;
    color: $color-red;
    padding: 15px;
    border: 1px solid $color-red;
    border-radius: 5px;
    margin-bottom: 20px;
    width: 80%;
    max-width: 500px;
  }
  
  .back-link {
    font-size: 1em;
    color: $color-black;
    text-decoration: none;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  