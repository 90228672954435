@import '../abstracts/variable';
@import '../abstracts/mixins';

.contact-section {
  @include flex-center(column);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    margin-bottom: 20px;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      margin-bottom: 15px;
      font-weight: bold;
      color:$color-black;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      margin-top: 5px;
      @include border-radius($border-radius-small);
      border: 1px solid #ddd;

    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }

    button {
      padding: 10px;
      border: none;
      @include border-radius($border-radius-small);
      background-color: $color-gray-dark;
      color: $color-white;
      font-size: $font-size-base;
      cursor: pointer;
      @include transition(background-color, $transition-medium);

      &:hover {
        background-color: $color-gray-medium;
      }
    }
  }

  .form-status {
    margin-top: 15px;
    font-size: $font-size-small;
    color: $color-black;
  }
}
