@import '../abstracts/media';
@import '../abstracts/variable';
@import '../abstracts/mixins';
/* Section Container */
.section-container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    border-radius: 25px;
    gap: 2rem;
    width: 100%;
    box-sizing: border-box; /* Ensure padding does not affect width */
  
    /* Add a top border animation */
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, $color-black, $color-gray-light, $color-white);
      background-size: 300% 100%;
      animation: border-animation 10s infinite;
      z-index: 1;
      border-radius: 25px; /* Match the section-container's border-radius */
      margin: 0 auto; /* Center the border inside the container */
    }
  }
  
  /* Animation Keyframes */
  @keyframes border-animation {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  
  /* Mobile Styles */
  @include respond-to(mobile) {
    .section-container {
      padding: 15px;
      margin-bottom: 30px;
    }
  

  }
  