/* Main Layout */
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.main-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden; /* Prevents overflow on main page */
  padding: 20px; /* Removes extra padding */
}

.title-banner {
  position: relative;
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 2rem;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 2px;
  transition: color 0.5s ease-in-out;
  /* Initial underline (hidden) */
  /* Hover effect */
}
.title-banner::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #000000;
  transition: all 0.5s ease-in-out;
}
.title-banner:hover::after {
  width: 100%; /* Expand underline to full width */
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none; /* Prevent interaction issues */
}

.article-container {
  width: 100%;
  max-width: 1400px;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

.typing-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  text-align: center;
}
.typing-container .typing-text {
  font-size: 2rem;
  overflow: hidden;
  display: inline-block;
  color: #000000;
  font-family: "Roboto Mono", "Courier New", Courier, monospace;
  height: auto;
  white-space: nowrap;
  word-break: break-word;
  line-height: 1.5;
  /* Add a placeholder to maintain layout consistency */
  /* Tablet View */
  /* Mobile View */
}
.typing-container .typing-text::before {
  content: "_"; /* Placeholder for consistent height */
  visibility: hidden; /* Hide the placeholder visually */
}
.typing-container .typing-text::after {
  content: "_"; /* Simulate the typing cursor */
  animation: blink 0.8s steps(2) infinite; /* Cursor blink effect */
}
@media (max-width: 768px) {
  .typing-container .typing-text {
    font-size: 1.5rem;
    white-space: normal;
    border-right: none;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .typing-container .typing-text {
    font-size: 1.2rem;
    white-space: normal;
    line-height: 1.4;
    border-right: none; /* Remove the cursor effect */
  }
}

/* Cursor blink animation */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Media Queries for responsiveness */
@media (max-width: 1000px) {
  .main-page {
    padding: 10px;
  }
  .article-container {
    max-width: 100%; /* Full width on smaller screens */
    padding: 15px;
  }
  .typing-container {
    height: 2rem; /* Reduce height for smaller screens */
  }
  .typing-container .typing-text {
    font-size: 1.5rem; /* Adjust text size */
  }
  .title-banner {
    font-size: 2.2em;
    padding: 8px 16px;
  }
  .title-banner::after {
    height: 1.5px; /* Thinner underline for smaller screens */
  }
  .profile-section,
  .Skills-section,
  .projects-section,
  .login-section {
    align-self: center;
    max-width: 100%;
  }
  .project-list {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .pie-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .category-container {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  body {
    font-size: 14px;
  }
  h1,
  h2,
  h3 {
    font-size: 1.5em;
  }
}/*# sourceMappingURL=Layout.css.map */