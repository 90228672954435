.footer {
  background-color: #000000;
  color: #ffffff;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.footer .footer-content {
  display: flex;
  gap: 4rem;
  font-size: 1rem;
}
.footer .footer-content a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: color 0.2s ease;
}
.footer .footer-content a svg {
  width: 40px;
  height: 40px;
}
.footer .footer-content a:hover {
  color: #ccc;
}
@media (max-width: 1000px) {
  .footer {
    padding: 1.8rem;
  }
  .footer .footer-content {
    gap: 5rem;
  }
  .footer .footer-content svg {
    width: 40px;
    height: 40px;
  }
}/*# sourceMappingURL=Footer.css.map */